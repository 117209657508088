import { render, staticRenderFns } from "./Methodology.vue?vue&type=template&id=43a2dee3&scoped=true"
import script from "./Methodology.vue?vue&type=script&lang=js"
export * from "./Methodology.vue?vue&type=script&lang=js"
import style0 from "./Methodology.vue?vue&type=style&index=0&id=43a2dee3&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43a2dee3",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/testing.market-scope.com/front/landing/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('43a2dee3')) {
      api.createRecord('43a2dee3', component.options)
    } else {
      api.reload('43a2dee3', component.options)
    }
    module.hot.accept("./Methodology.vue?vue&type=template&id=43a2dee3&scoped=true", function () {
      api.rerender('43a2dee3', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Methodology.vue"
export default component.exports